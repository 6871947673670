export const filter = {
    inputs: [
        {
            name: 'title',
            label: 'Título',
            col: '4',
            type: 'text'
        },
    ],
    selects: []
};
