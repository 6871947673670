export const table = [
    {
        column: 'Título',
    },
    {
        column: 'Perguntas',
    },
    {
        column: 'Ações',
    },
];

export const keys = ['title', 'total_questions', 'actions'];
